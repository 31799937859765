import React, { useState, useEffect, useContext } from "react"
import produce from 'immer'

import AccountPage from "../../components/layout/AccountPage"
import SignList from "../../components/SignList"

import { UserContext} from "../../state/UserContext"
import { postData } from "../../services/server"

const AccountSignsPage = () => {
  const userContext = useContext(UserContext);

  const [signs, setSigns] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(
    () => {
        setLoading(true);

        postData("/api/signs/foremail?secure=false", {email: userContext.email},
          function(result) {
              setSigns(result);
          },
          function(error, status) {
              setError("Error loading signs.");
          },
          function() {
              setLoading(false);
          }
        );
    }, 
    []
  );

  return (
    <AccountPage 
      page="signs" 
      breadcrumb={[
        {url: "/", title: "Home"},
        {url: null, title: "Signs"}
      ]}
      loading={loading} 
      error={error} 
      overflow={false}
    >
        { signs && 
            <>
              { signs.length ?
                <SignList 
                  from="mysigns"
                  signs={signs} 
                  canDelete={true}
                  onChanged={(sign) => {
                    setSigns(produce(draft => {
                      const index = draft.findIndex(s => s.id == sign.id)

                      if (index != -1)
                        draft[index] = sign
                    }));        
                  }}
                  onDelete={(sign) => {
                    setSigns(produce(draft => {
                      const index = draft.findIndex(s => s.id == sign.id)

                      if (index != -1)
                        draft.splice(index, 1);
                    }));
                  }}
                />
                :
                <p>No signs found.</p> 
              }
            </>
        }
    </AccountPage>
  )
}

export default AccountSignsPage
